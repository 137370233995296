import { useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { Button } from "../../../../components/button/Button";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useAppSelector } from "../../../../store";
import { ReactComponent as ArrowNav } from "../../../../styles/icons/ArrowNav.svg";
import { FeedV2 } from "../../../feed/FeedV2";
import s from "./HomeView.module.scss";
import { PreviewTitle } from "./previewTemaplate/PreviewTemplate";
export const PersonalFeedPreview: React.FC<{
  products: MiniProduct[];
  onOpenView: () => void;
  onClickProduct: (p: MiniProduct) => void;
}> = ({ products, onClickProduct, onOpenView }) => {
  const [windowName, setWindow] = useState<string>("");
  const { user } = useAppSelector((s) => s.app);
  if (!user) return null;
  return (
    <>
      {/* <ViewPreviewTemplate
        cta="See more"
        // description="Gigi recommends items in real-time based on your shopping behavior"
        onClickCta={onOpenView}
        title={`${capitalize(user.name ?? user!.info?.name)}'s Feed`}
      > */}
      <div className={s.previewContainer}>
        <PreviewTitle>Curated Just For You</PreviewTitle>
        <div className={s.feedPreview}>
          {/* <div className={s.columns}>
            {products.map((p, index) => (
              <div className={s.columnItem}>
                <ProductCard hideLike product={p} onClick={onClickProduct} />
              </div>
            ))}
          </div> */}
          <ProductsGrid
            products={products}
            isLoading={false}
            hasNextPage={false}
            fetchNextPage={() => {}}
            isFetchingNextPage={false}
          />
          <div className={s.footer}>
            <Button onClick={onOpenView}>
              Explore more
              <ArrowNav />
            </Button>
          </div>
        </div>
      </div>
      {/* </ViewPreviewTemplate> */}

      <SlidingWindow
        isOpen={!!windowName}
        onClose={() => setWindow("")}
        title={"My Personalized Feed"}
        direction="right"
        keepMounted={false}
      >
        <FeedV2 />
      </SlidingWindow>
    </>
  );
};
