import Gigi from "../../../styles/icons/GigiV2.png";
import { Button, ButtonProps } from "../Button";
export const ChatButton = (props: ButtonProps) => {
  return (
    <Button type="secondary" {...props}>
      <div className="alignCenter gapSm">
        <img src={Gigi} alt="Gigi" width={24} height={24} />
        {props.children}
      </div>
    </Button>
  );
};
