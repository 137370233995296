import { ChatButton } from "../../button/instances/ChatButton";
import { SaveButton } from "../../button/instances/SaveButton";
import { ShopButton } from "../../button/instances/ShopButton";
import { ActionsFooter } from "../ActionsFooter";

export const ProductActionsFooter: React.FC<{
  onSave: () => void;
  onShop: () => void;
  onRefine: () => void;
}> = ({ onSave, onShop, onRefine }) => {
  return (
    <ActionsFooter>
      <SaveButton size="lg" text="Save" onClick={onSave} />

      <ChatButton size="lg" onClick={onRefine}>
        Refine
      </ChatButton>
      <ShopButton size="lg" onClick={onShop}>
        Shop
      </ShopButton>
    </ActionsFooter>
  );
};
