import { ReactNode } from "react";
import s from "./PreviewTemplate.module.scss";
interface Props {
  preTitle?: string;
  title: string;
  description?: ReactNode;
  cta: ReactNode;
  onClickCta: () => void;
  children: ReactNode;
}

export const ViewPreviewTemplate: React.FC<Props> = ({
  title,
  description,
  cta,
  onClickCta,
  children,
  preTitle,
}) => {
  return (
    <div className={s.container}>
      <div className={s.details}>
        {preTitle && <p className={s.productsCount}>{preTitle}</p>}
        <h3 className={s.title}>{title}</h3>
        {description && (
          <span className={"subTitle center"}>{description}</span>
        )}
      </div>
      {children}
      {/* <div className={s.cta}>
        <Button onClick={onClickCta}>{cta}</Button>
      </div> */}
    </div>
  );
};

export const PreviewTitle: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <h3 className={s.title}>{children}</h3>;
};
