import { isEmpty } from "lodash";
import { Pill } from "../../../../components/pill/Pill";
import { TypingDisplay } from "../../../../components/typingDisplay/TypingDisplay";
import { useHomeSugguestions } from "../../../../hooks/query/suggestions/useHomeSugguestions";
import { useSearchPageSuggestions } from "../../../../hooks/query/suggestions/useSearchPageSuggestions";
import { useSearchTermSuggestions } from "../../../../hooks/query/suggestions/useSearchTermSuggestions";
import { useChat } from "../../../../hooks/useChat";
import { ReactComponent as Stars } from "../../../../styles/icons/ai.svg";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";

export const GigiMessage = ({
  text,
  isLong,
}: {
  text: string;
  isLong?: boolean;
}) => {
  return (
    <div
      style={{ minHeight: isLong ? "60px" : "unset" }}
      className="alignStart gapMd flex"
    >
      <div
        style={{
          minHeight: "28px",
          maxHeight: "28px",
          minWidth: "28px",
          maxWidth: "28px",
        }}
      >
        <Gigi width={28} height={28} />
      </div>
      <p style={{ textAlign: "left" }} className="subTitle">
        <TypingDisplay text={text} />
      </p>
    </div>
  );
};

export const GigiHomeSuggestions = () => {
  const { data: suggestions, isLoading } = useHomeSugguestions();
  const { onChatWithEngine, onChat } = useChat();

  return (
    <GigiPreview
      suggestions={suggestions ?? []}
      isLoading={isLoading}
      loadingCount={10}
      onClick={onChatWithEngine}
    />
  );
};
export const GigiSearchSuggestions = ({
  onSearch,
}: {
  onSearch: (s: string) => void;
}) => {
  const { data: suggestions, isLoading } = useSearchPageSuggestions();
  return (
    <GigiPreview
      suggestions={suggestions}
      isLoading={isLoading}
      loadingCount={10}
      onClick={onSearch}
    />
  );
};
export const GigiSearchTermSuggestions = ({ term }: { term: string }) => {
  const { onChatWithEngine } = useChat();
  const { data: suggestions, isLoading } = useSearchTermSuggestions(term);
  return (
    <GigiPreview
      suggestions={suggestions ?? []}
      isLoading={isLoading}
      loadingCount={6}
      onClick={onChatWithEngine}
    />
  );
};

export const GigiPreview: React.FC<{
  suggestions: string[] | undefined;
  isLoading: boolean;
  loadingCount?: number;
  onClick: (s: string) => void;
}> = ({ suggestions, isLoading, loadingCount = 10, onClick }) => {
  return (
    // <GradientBackground>
    <div
      style={{
        overflow: "hidden",
        maxWidth: "100%",
        minWidth: "100%",

        padding: "0px 16px 16px 16px",
      }}
      className="column gapMd"
    >
      {/* // <div> */}
      <div className="column gapLg flex1 alignCenter">
        {/* <h2>Gigi's Recs</h2> */}
        {/* <GigiMessage text="Here are some suggestions for you based on your preferences" /> */}
      </div>

      <div className="column gapMd">
        <div className="alignCenter flex1 ">
          <div className=" column gapMd scrollAuto">
            {(!isEmpty(suggestions)
              ? [
                  suggestions!.slice(0, suggestions!.length / 2),
                  suggestions!.slice(suggestions!.length / 2),
                ]
              : [
                  Array(loadingCount / 2).fill(null),
                  Array(loadingCount / 2).fill(null),
                ]
            ).map((row, i) => (
              <div key={i} className="alignCenter flex gapMd">
                {row.map((p) => (
                  <Pill
                    type="secondary"
                    size="sm"
                    isLoading={isLoading}
                    onClick={() => onClick(p)}
                  >
                    <div className="icon withFill dark">
                      <Stars width={14} height={14} />
                    </div>
                    {p}
                  </Pill>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>

    // </GradientBackground> .
  );
};
