import React, { ReactNode, forwardRef, useEffect, useState } from "react";
import { ReactComponent as LensIcon } from "../../../styles/icons/Lens.svg";
import { ReactComponent as PaletteIcon } from "../../../styles/icons/Palette.svg";
import { Input, InputProps } from "../Input";
import { SearchOptions } from "./SearchOptions/SearchOptions";
import styles from "./search.module.scss";

interface SearchProps extends InputProps {
  autoCompleteOptions?: { label: ReactNode; value: string }[] | null;
  onSelectAutoComplete?: (value: string) => void;
  onSearch?: () => void;
  theme?: "light" | "dark";
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      value,
      onChange,
      disabled,
      autoCompleteOptions,
      onSelectAutoComplete,
      isLoading,
      placeholder,
      onSearch,
      containerClassName,
      theme,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [focusIndex, setFocusIndex] = useState(-1);

    const onSubmit: React.FormEventHandler = (ev) => {
      ev.preventDefault();
      onSearch && onSearch();
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (ev) => {
      if (ev.key === "Enter") {
        if (focusIndex === -1) {
          onSearch && onSearch();
        } else if (autoCompleteOptions && onSelectAutoComplete) {
          onSelectAutoComplete(autoCompleteOptions[focusIndex].value);
        }
        setShowAutoComplete(false);
      }
      if (autoCompleteOptions && showAutoComplete) {
        if (ev.key === "ArrowUp" && focusIndex > 0) {
          setFocusIndex(focusIndex - 1);
        }
        if (
          ev.key === "ArrowDown" &&
          focusIndex < autoCompleteOptions.length - 1
        ) {
          setFocusIndex(focusIndex + 1);
        }
      }
      if (ev.key === "Escape") setShowAutoComplete(false);
    };

    useEffect(() => {
      if (focusIndex !== -1 && autoCompleteOptions) {
        onChange(autoCompleteOptions[focusIndex].value);
      }
    }, [focusIndex]);

    useEffect(() => {
      if (!showAutoComplete) {
        setFocusIndex(-1);
      }
    }, [showAutoComplete]);
    return (
      <div className={styles.container}>
        <div
          className={`${styles.clickHandler} ${
            showAutoComplete ? styles.show : ""
          }`}
          onClick={() => setShowAutoComplete(false)}
        />
        <form action="" onSubmit={onSubmit}>
          <div
            className={`${styles.inputContainer}`}
            onKeyDown={handleKeyDown}
            onClick={() => autoCompleteOptions && setShowAutoComplete(true)}
          >
            <Input
              ref={ref}
              theme={theme}
              value={value}
              onChange={(value) => {
                onChange(value);
                autoCompleteOptions && setShowAutoComplete(true);
              }}
              onEnter={(val) => onSearch && onSearch()}
              disabled={disabled}
              placeholder={placeholder}
              containerClassName={`${styles.containerInp}  ${
                showAutoComplete && autoCompleteOptions
                  ? styles.autoCompleteOpen
                  : ""
              } ${containerClassName} `}
              isLoading={isLoading}
              onBlur={(ev) => onBlur && onBlur(ev)}
              iconEnd={
                <div className={"alignCenter gapMd"}>
                  <LensIcon width={20} height={20} />
                  <PaletteIcon width={20} height={20} />
                </div>
              }
              onFocus={(ev) => {
                autoCompleteOptions && setShowAutoComplete(true);
                onFocus && onFocus(ev);
              }}
              weirdMobileBehaviorIconStartSearch
            />

            {autoCompleteOptions && showAutoComplete && (
              <div className={styles.autoComplete}>
                {/* <div className={styles.autoCompleteList}>
              {autoCompleteOptions.length ? (
                autoCompleteOptions.map((option, index) => (
                  <div
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onSelectAutoComplete &&
                        onSelectAutoComplete(option.value);
                      setShowAutoComplete(false);
                    }}
                    className={`${styles.autoCompleteItem} ${
                      index === focusIndex ? styles.focused : ""
                    }`}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <div className={styles.noResultsItem}>No results found...</div>
              )}
            </div> */}
                <SearchOptions
                  searchOptions={autoCompleteOptions}
                  focusIndex={focusIndex}
                  onSelect={(value, ev) => {
                    ev.stopPropagation();
                    onSelectAutoComplete && onSelectAutoComplete(value);
                    setShowAutoComplete(false);
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
);
