import axios from "axios";
import { useRef } from "react";
import { Rnd } from "react-rnd";

const CurvedResizableBox = ({
  onSelection,
}: {
  onSelection: ({
    coordinates,
  }: {
    coordinates: [number, number, number, number];
  }) => void;
}) => {
  const isResize = useRef<boolean>(false);
  const handleResizeStop = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: any
  ) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("resize");
    const rect = ref.getBoundingClientRect();
    const x1 = rect.left;
    const y1 = rect.top;
    const x2 = rect.right;
    const y2 = rect.bottom;

    onSelection({
      coordinates: [x1, y1, x2, y2],
    });
  };

  const handleDragStop = (e: any, d: any) => {
    // if (e.type !== "dragstop") return;
    if (isResize.current) return;
    console.log("dragstop");

    const rect = e.target.getBoundingClientRect();
    const x1 = rect.left;
    const y1 = rect.top;
    const x2 = rect.right;
    const y2 = rect.bottom;

    onSelection({
      coordinates: [x1, y1, x2, y2],
    });
  };

  const sendBoxToServer = async (data: any) => {
    console.log("Sending box:", data);
    try {
      await axios.post("/api/selection", data);
    } catch (err) {
      console.error("Failed to send box data:", err);
    }
  };

  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 250,
        height: 300,
      }}
      bounds="parent"
      onResizeStop={handleResizeStop}
      onDragStop={handleDragStop}
      onResizeStart={() => (isResize.current = true)}
      onResize={() => (isResize.current = true)}
      onDragStart={() => (isResize.current = false)}
      style={{
        // border: "2px solid white",
        backgroundColor: "#ffffff50",
        boxSizing: "border-box",
        borderRadius: "16px",
      }}
      resizeHandleComponent={{
        topLeft: <CornerHandle position="top-left" />,
        topRight: <CornerHandle position="top-right" />,
        bottomLeft: <CornerHandle position="bottom-left" />,
        bottomRight: <CornerHandle position="bottom-right" />,
      }}
      resizeHandleStyles={{
        topLeft: { ...handleStyle, borderRadius: "4px", top: "0", left: "0" },
        topRight: { ...handleStyle, borderRadius: "4px", top: "0", right: "0" },
        bottomLeft: {
          ...handleStyle,
          borderRadius: "4px",
          bottom: "0",
          left: "0",
        },
        bottomRight: {
          ...handleStyle,
          borderRadius: "4px",
          bottom: "0",
          right: "0",
        },
      }}
    />
  );
};

const handleStyle = {
  width: "32px",
  height: "32px",
};

const CornerHandle = ({ position }: { position: string }) => {
  const borderStyles = {
    "top-left": {
      borderTop: "4px solid white",
      borderLeft: "4px solid white",
      borderTopLeftRadius: "16px",
      left: "0",
      top: "0",
    },
    "top-right": {
      borderTop: "4px solid white",
      borderRight: "4px solid white",
      borderTopRightRadius: "16px",
      right: "0",
      top: "0",
    },
    "bottom-left": {
      borderTop: "4px solid transparent",
      borderRight: "4px solid transparent",
      borderBottom: "4px solid white",
      borderLeft: "4px solid white",
      borderBottomLeftRadius: "16px",
      left: "0",
      bottom: "0",
    },
    "bottom-right": {
      borderTop: "4px solid transparent",
      borderLeft: "4px solid transparent",
      borderBottom: "4px solid white",
      borderRight: "4px solid white",
      borderBottomRightRadius: "16px",
      right: "0",
      bottom: "0",
    },
  };

  return (
    <div
      style={{
        width: 32,
        height: 32,
        boxSizing: "border-box",
        ...borderStyles[position as keyof typeof borderStyles],
      }}
    />
  );
};

export default CurvedResizableBox;
