import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  FilterUnit,
  ProductDetails,
} from "../../@types/entities/product.types";
import { AppLoader } from "../../App";
import { ProductActionsFooter } from "../../components/ActionsFooter/ProductActionsFooter/ProductActionsFooter";
import { Button } from "../../components/button/Button";
import { PageContainer } from "../../components/page/Page";
import { LogoHeader } from "../../components/searchHeader/SearchHeader";
import { SwipeableWindow } from "../../components/slidingWindow/SwipeableWindow";
import { SnackbarProvider, useSnackbar } from "../../contexts/snackbar";
import { useLookalikeProducts } from "../../hooks/query/lookbook/useLookbook";
import { useFilterActions } from "../../hooks/system/useFilterActions";
import { ReactComponent as AppleAppStore } from "../../styles/icons/AppleAppStore.svg";
import { BaseLookalikeProducts } from "./LookalikeProducts";
export const LookalikePage = () => {
  const { parseId } = useParams();
  return <LookalikeProducts id={parseId} />;
};

export const LookalikeProducts: React.FC<{ id?: string }> = ({ id }) => {
  const isMount = useRef(false);
  const [filters, setFilters] = useState<FilterUnit[]>();
  const { data, isLoading } = useLookalikeProducts(id, filters);
  // const { data: filterOptions } = useLookalikeFilterOptions("dress");
  const { updateFilter } = useFilterActions();
  const consistentPathLookalike = useRef<string>();
  const [appLoaded, setAppLoaded] = useState(false);
  const [windowContent, setWindowContent] = useState<ReactNode>();
  const navigate = useNavigate();

  return (
    <>
      <SnackbarProvider>
        <>{appLoaded && <SnackbarTooltip />}</>
      </SnackbarProvider>
      <AppLoader isReady={appLoaded} />
      <PageContainer>
        <div className="column gapMd">
          <div style={{ marginTop: "24px" }}>
            <LogoHeader />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 8px",
            }}
          >
            <div className="flex1">
              <div>
                <Button
                  type="cta"
                  theme="light"
                  onClick={() => setWindowContent(<DownloadAppContent />)}
                >
                  Get the Gigi app
                </Button>
              </div>
            </div>
            <div className="flex1 justifyEnd">
              <Button type="tertiary" onClick={() => {}}>
                Sign In
              </Button>
            </div>
          </div>
          <BaseLookalikeProducts
            products={[]}
            isLoading={isLoading || !data?.path}
            lookalikeImg={data?.path}
            onImageLoad={(e) => {
              setAppLoaded(true);
            }}
            // filters={
            //   filterOptions &&
            //   filters && (
            //     <FiltersPreview
            //       options={adaptFilterOptions(filterOptions)}
            //       activeFilters={keyBy(filters, "key")}
            //       onChange={(key, value) =>
            //         setFilters((prev) => updateFilter(key, value, prev))
            //       }
            //       previewFilters={PREVIEW_LOOKALIKE_FILTERS}
            //     />
            //   )
            // }
            onClickProduct={(p) => {
              window.open(p.vendors[0].url, "_blank");
            }}
          />
          <ProductActionsFooter
            onSave={() => setWindowContent(<SignInContent />)}
            onShop={() => {}}
            onRefine={() => {}}
          />
        </div>
        <SwipeableWindow
          isOpen={!!windowContent}
          onClose={() => setWindowContent(undefined)}
          direction="bottom"
          height="30vh"
        >
          <div className="paddingLg column gapXlg">{windowContent}</div>
        </SwipeableWindow>
      </PageContainer>
    </>
  );
};

const DownloadAppContent = () => {
  return (
    <div className="column gapXlg alignCenter">
      <h4 style={{ fontFamily: "Inter" }}>Own your style, own your time</h4>
      <p style={{ textAlign: "center", fontSize: "14px", fontWeight: 300 }}>
        Download the Gigi app to get access to your favorite brands and
        products.
      </p>
      <div className="flex1 justifyCenter alignCenter">
        <AppleAppStore
          width={"200px"}
          height={"55px"}
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/stili/id6499213024",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
};
const SignInContent = () => {
  return (
    <div className="column gapXlg">
      <div className="column gapXlg alignCenter">
        <h4 style={{ fontFamily: "Inter" }}>
          Register For an elevated shopping experience.
        </h4>
        <p style={{ textAlign: "center", fontSize: "14px", fontWeight: 300 }}>
          To continue shopping and saving your favorite looks, please sign in or
          create an account
        </p>
      </div>
      <div className="flex1 column">
        <Button size="lg" onClick={() => {}}>
          Sign In
        </Button>
      </div>
    </div>
  );
};

const labelKeyMap = {
  length_group: "length",
  skirt_group: "skirt",
  strap_group: "strap",
  neckline_group: "neckline",
  sleeve_length: "sleeve",
  pattern_style: "pattern",
  main_color_specific: "color",
};

export const adaptFilterOptions = (filterOptions: FilterUnit[]) => {
  return filterOptions.map((f) => {
    let customValue = undefined;
    if (f.key === "price") {
      return {
        ...f,
        label: labelKeyMap[f.key as keyof typeof labelKeyMap] ?? f.key,
        customValuePreview: (value: any) => {
          if (value) {
            return `$${value.gt} - $${value.lt}`;
          }
          return "";
        },
      };
    }
    return {
      ...f,
      label: f.label ?? f.key,
    };
  });
};

const convertLookalikeToActiveFilters = (
  filterOptions: FilterUnit[],
  lookalike: any
): FilterUnit[] => {
  return filterOptions.map((f) => {
    if (f.key === "price") {
      return {
        key: f.key,
        value: null,
      };
    }
    return {
      key: f.key,
      value: lookalike[f.key as keyof ProductDetails]
        ? [lookalike[f.key as keyof ProductDetails]]
        : [],
    };
  }) as FilterUnit[];
};

const SnackbarTooltip = () => {
  const snackbar = useSnackbar();

  useEffect(() => {
    snackbar.setContent("Position the box over the item you want to find", {
      delay: 2000,
    });
    setTimeout(() => {
      snackbar.open();
    }, 1000);
  }, []);

  return <></>;
};
