import { find, shuffle } from "lodash";
import { Button } from "../../../../components/button/Button";
import { Carousel } from "../../../../components/carousels/Carousel";
import { useLookbooks } from "../../../../hooks/query/lookbook/useLookbook";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { ReactComponent as ArrowNav } from "../../../../styles/icons/ArrowNav.svg";
import { LookbookDetails } from "../../../lookbook/LookbookDetails/LookbookDetails";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";
export const LookbooksPreview: React.FC<{ onOpenView: () => void }> = ({
  onOpenView,
}) => {
  const [callWindow] = useViewCaller();
  const { data } = useLookbooks();
  return (
    <ViewPreviewTemplate
      title="Inspo From The Stars"
      cta="See More"
      onClickCta={() => onOpenView()}
    >
      <Carousel
        settings={{ perView: 1.05, enablePagination: false }}
        items={
          shuffle(data?.items)
            .slice(0, 4)
            .map((l) => ({
              // title: l.name,
              // description: l.description.split(".")[0],
              id: l._id,
              element: (
                <LookbookBanner
                  url={l.mainImg}
                  lookbook={l}
                  onClickCta={() => onOpenView()}
                />
              ),
            })) ?? []
        }
        onClickItem={(id) =>
          callWindow({
            id: "lookbookWindow",
            data: {
              lookbook: find(
                data?.items,
                (l) => l._id === id
              ) as LookbookDetails,
            },
          })
        }
      />
    </ViewPreviewTemplate>
  );
};

const LookbookBanner: React.FC<{
  url: string;
  lookbook: any;
  onClickCta: () => void;
}> = ({ url, lookbook, onClickCta }) => {
  return (
    <div style={{ padding: "0 16px" }} className="flex justifyCenter">
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={url}
          alt=""
          style={{
            borderRadius: "24px",
            aspectRatio: 3 / 2,
            objectFit: "cover",
            objectPosition: "top",
            height: "250px",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "30%",
            display: "flex",
            alignItems: "center",
            right: 0,
            color: "white",
            background:
              "linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))",
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
            // backdropFilter: "blur(2px)",
            padding: "6px",
            justifyContent: "space-between",
          }}
        >
          <div className="column gapMd">
            <div>{lookbook.name}</div>
          </div>
          <Button type="secondary" onClick={() => onClickCta()}>
            View
            <div className="icon withStroke dark">
              <ArrowNav />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
