import { forwardRef, ReactNode, useContext } from "react";
import { AppContext } from "../../App";
import s from "./Page.module.scss";
export const PageContainer = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }, ref) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={s.container}
      ref={ref}
      style={{
        paddingTop: isMobile ? "42px" : "",
        paddingBottom: isMobile ? "96px" : "",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100vh",
        flex: "1",
      }}
    >
      {children}
    </div>
  );
});
