import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { isEmpty, isEqual } from "lodash";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { queryClient } from "../..";
import { MiniProduct } from "../../@types/entities/product.types";
import { useAutoToggleToList } from "../../hooks/query/lists/useAddToList";
import { useWindowUiManager } from "../../hooks/ui/useWindowUiManager";
import {
  ProductCard,
  ProductCardProps,
} from "../../pages/shop/cmps/productCard/ProductCard";
import { dislikeProduct } from "../../services/api/products/products.api";
import { socketService } from "../../services/mock/socket";
import { InfiniteScroller } from "../infiniteScroller/InfiniteScroller";
import { InterruptionBox } from "../interruptionBox/InterruptionBox";
import { ProductSkeleton } from "../skeleton/ProductSkeleton";
import s from "./ProductsGrid.module.scss";
interface ProductsGridProps {
  products: MiniProduct[];
  isLoading: boolean;
  hasNextPage: boolean | null;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  invalidateKeyOnDislike?: any[];
  allowDislike?: boolean;
  disableClick?: boolean;
  notifyClickProduct?: (p: MiniProduct) => void;
}

export const ProductsGrid: React.FC<ProductsGridProps> = ({
  products,
  isLoading,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  invalidateKeyOnDislike,
  allowDislike,
  disableClick,
  notifyClickProduct,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [style, onOpen, onClose] = useWindowUiManager();
  const [onToggleLike] = useAutoToggleToList();

  const onClickProduct = (product: MiniProduct) => {
    console.log("click");
    if (!product) return;
    socketService.emit("windowOpen", "product", product);
    if (notifyClickProduct) {
      notifyClickProduct(product);
    }
  };
  if (!isLoading && isEmpty(products))
    return (
      <div className="column gapSm alignCenter justifyCenter flex1">
        <span className="subTitle center">No results found...</span>
        <span className="subTitle center">Try changing your filters</span>
      </div>
    );
  return (
    <div
      className={`${s.results} ${s.columns}`}
      ref={ref}
      style={style.current}
    >
      {isLoading && <ProductSkeleton repeat={4} />}

      {!isEmpty(products) && (
        <>
          {/* <button
            onClick={() =>
              console.log(
                JSON.stringify(
                  products.map((p) => p._id),
                  null,
                  2
                )
              )
            }
          >
            PRINT
          </button> */}
          <ProductList
            products={products}
            onClickProduct={(p) => !disableClick && onClickProduct(p)}
            invalidateKey={invalidateKeyOnDislike}
            allowDislike={allowDislike}
            onToggleLike={onToggleLike}
          />
          {isFetchingNextPage && <ProductSkeleton repeat={2} />}
          <InfiniteScroller
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            bottom={100}
          />
        </>
      )}
    </div>
  );
};

const ProductList: React.FC<{
  products: MiniProduct[];
  onClickProduct: (product: MiniProduct) => void;
  invalidateKey?: any[];
  allowDislike?: boolean;
  onToggleLike: (id: string) => void;
}> = ({
  products,
  onClickProduct,
  invalidateKey,
  allowDislike,
  onToggleLike,
}) => {
  const { mutateAsync: hideProduct } = useMutation({
    mutationFn: async (productId: string) => {
      const res = await dislikeProduct(productId);
      if (!!res?.confirmBrand) {
        // TODO:confirm brand dislike modal
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: invalidateKey });
    },
    mutationKey: ["hideProduct"],
  });
  return (
    <>
      {products?.map((product, index) => (
        <>
          {allowDislike ? (
            <SwipeableProductCard
              product={product}
              onClick={onClickProduct}
              key={`${product._id}_${index}`}
              onHideProduct={(id) => hideProduct(id)}
            />
          ) : (
            <div
              id="productCard"
              className={`${s.productCard}`}
              key={`${product._id}_${index}`}
            >
              <ProductCard
                product={product}
                className={s.overrideClassName}
                onClick={onClickProduct}
                onToggleLike={onToggleLike}
                onFailedImg={() =>
                  // delete product
                  {}
                }
              />
            </div>
          )}
          {index % 30 === 0 && <InterruptionBox type="filters" />}
        </>
      ))}
    </>
  );
};

const SwipeableProductCard: React.FC<
  ProductCardProps & { onHideProduct: (id: string) => void }
> = ({ onHideProduct, ...props }) => {
  const swiper = useRef<SwiperClass | null>(null);
  const [hide, setHide] = useState(false);

  const onSwipeToHide = () => {
    swiper.current?.disable();
    onHideProduct(props.product._id!);
    setHide(true);
  };
  return (
    <div>
      <Swiper
        onSwiper={(instance: SwiperClass) => (swiper.current = instance)}
        onSlideChange={onSwipeToHide}
        slidesPerView={1}
        spaceBetween={5}
        style={{ display: "flex" }}
      >
        <SwiperSlide>
          <div id="productCard" className={`${s.productCard}`}>
            <ProductCard {...props} />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            height: "100%",
            background: hide ? "transparent" : "red",
            transition: "background 1.5s",
            aspectRatio: 5 / 9,
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VisibilityOffRoundedIcon />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const MemoizedProductList = React.memo(ProductList, (prevProps, nextProps) =>
  isEqual(prevProps.products, nextProps.products)
);
