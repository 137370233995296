import { ReactComponent as SaveIcon } from "../../../styles/icons/Like.svg";
import { Button, ButtonProps } from "../Button";
export const SaveButton: React.FC<
  Omit<ButtonProps, "children"> & { text?: string }
> = ({ text, ...props }) => {
  return (
    <Button
      style={!text ? { borderRadius: "50%", padding: "0px" } : {}}
      type="secondary"
      {...props}
    >
      <div className="alignCenter gapSm">
        <SaveIcon />
        {text}
      </div>
    </Button>
  );
};
