import styled from "@emotion/styled";
import {
  Grow,
  SlideProps,
  Snackbar,
  SnackbarOrigin,
  SnackbarProps,
} from "@mui/material";
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

const SnackbarContext = createContext({
  openSnackbar: (
    content: ReactNode,
    options?: { delay?: number; origin?: SnackbarOrigin }
  ) => {},
  setContent: (
    content: ReactNode,
    options?: { delay?: number; origin?: SnackbarOrigin }
  ) => {},
  open: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

const DEFAULT_ORIGIN: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export const SnackbarProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [snackbarContent, setSnackbarContent] = useState<{
    content: ReactNode;
    origin: SnackbarOrigin;
    delay: number;
  } | null>();
  const [isOpen, setIsOpen] = useState(false);

  const openSnackbar = (
    content: ReactNode,
    options?: { delay?: number; origin?: SnackbarOrigin }
  ) => {
    setSnackbarContent({
      content,
      origin: options?.origin ?? DEFAULT_ORIGIN,
      delay: options?.delay ?? 800,
    });
    console.log(options);
    setIsOpen(true);
  };

  const open = () => {
    setIsOpen(true);
  };
  const setContent = (
    content: ReactNode,
    options?: { delay?: number; origin?: SnackbarOrigin }
  ) => {
    setSnackbarContent({
      content,
      delay: options?.delay ?? 800,
      origin: options?.origin ?? DEFAULT_ORIGIN,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSnackbarContent(null);
    }, snackbarContent?.delay ?? 800);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, setContent, open }}>
      {children}
      <AppSnackbar
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={snackbarContent?.delay}
        anchorOrigin={
          snackbarContent?.origin ?? { horizontal: "center", vertical: "top" }
        }
        TransitionComponent={SlideTransition}
        ContentProps={{
          style: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: 0,
          },
        }}
      >
        <div
          style={{
            backgroundColor: "#191919f3",
            padding: "15px 20px",
            fontSize: "14px",
            color: "white",
            borderRadius: "5px",
            maxWidth: "calc(100% - 20px)",
          }}
        >
          {snackbarContent?.content}
        </div>
      </AppSnackbar>
    </SnackbarContext.Provider>
  );
};

const AppSnackbar = styled(Snackbar)<SnackbarProps>(({ theme }) => ({
  "&.MuiSnackbar-root": {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: 0,
    height: "100vh",
    width: "100vh",
  },
}));

function SlideTransition(props: SlideProps) {
  return <Grow {...props} />;
}
