import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { Rating } from "@mui/material";
import { isEmpty, isEqual, isFunction, noop } from "lodash";
import React, {
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FilterUnit,
  ProductDetails,
} from "../../../@types/entities/product.types";
import { AppContext } from "../../../App";
import { Modal } from "../../../components/Modal/Modal";
import { Button } from "../../../components/button/Button";
import {
  DesktopMedia,
  TouchScreenMedia,
} from "../../../components/media/MediaQueries";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { useAppSelector } from "../../../store";

import { ReactComponent as Like } from "../../../styles/icons/Like.svg";
import { ReactComponent as Liked } from "../../../styles/icons/Liked.svg";
import { camelCaseToCapitalized } from "../../../utils/str";
import { SimilarProducts } from "./SimilarProducts/SimilarProducts";

import { ProductActionsFooter } from "../../../components/ActionsFooter/ProductActionsFooter/ProductActionsFooter";
import { AccordionItem } from "../../../components/accordion/Accordion";
import { Image } from "../../../components/image/Img";
import { Pill } from "../../../components/pill/Pill";
import { useAutoToggleToList } from "../../../hooks/query/lists/useAddToList";
import {
  ActionOnProduct,
  ActionsOnProduct,
} from "./ActionsOnProduct/ActionsOnProduct";
import styles from "./productDetails.module.scss";

export const ProductDisplay: React.FC<{
  children: ReactElement;
  onClose: () => void;
}> = ({ children, onClose }) => {
  return (
    <>
      <DesktopMedia>
        <Modal open onClose={onClose}>
          {children}
        </Modal>
      </DesktopMedia>
      <TouchScreenMedia>
        <SlidingWindow direction="bottom" onClose={onClose} isOpen>
          {children}
        </SlidingWindow>
      </TouchScreenMedia>
    </>
  );
};

export const ProductDetailsPage: FC<{
  onClose?: () => void;
  product?: ProductDetails;
  onQueryOnProduct: () => void;
}> = ({ product, onClose, onQueryOnProduct }) => {
  const [isFakeLoading, setIsFakeLoading] = useState(true);
  const { isMobile, onGoToVendor } = useContext(AppContext);
  const { likedProducts } = useAppSelector((store) => store.app);
  const [shouldShowFooter, setShouldShowFooter] = useState(false);
  const [onToggleLike] = useAutoToggleToList();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<FilterUnit[]>([]);
  const [selectedAction, setSelectedAction] = useState<ActionOnProduct | null>(
    null
  );
  // TODO: DO NOT REMOVE UNTIL YOU SOLVE PERFORMANCE ISSUES
  useEffect(() => {
    let cancel = false;
    setIsFakeLoading(true);
    setTimeout(() => {
      if (!cancel && product?._id) {
        setIsFakeLoading(false);
      }
    }, 500);
    return () => {
      cancel = true;
    };
  }, [product]);

  if (!product) return null;

  const actionResolver = (action: ActionOnProduct, data?: any) => {
    console.log(action, selectedAction);

    if (action === selectedAction) {
      setSelectedAction(null);
      setFilters([]);
      return;
    }
    const actions: Record<ActionOnProduct, () => void> = {
      dupe: () => {
        if (!scrollRef.current) return;
        setSelectedAction("dupe");
        setFilters([
          { key: "price", value: { lt: product.price, gt: 0 } as any },
        ]);
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      },
      eco: () => {
        if (!scrollRef.current) return;
        setSelectedAction("eco");
        //TODO:set filter for price
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      },
      refine: () => {
        setSelectedAction("refine");
        setFilters(data);
      },
      premium: () => {
        if (!scrollRef.current) return;
        setSelectedAction("premium");
        setFilters([{ key: "price", value: { gt: 200, lt: 100000 } as any }]);
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      },
      gigi: () => {
        onQueryOnProduct();
      },
    };
    if (isFunction(actions[action])) actions[action]();
  };

  return (
    <>
      {product && (
        <div
          onScroll={() => {
            if (!shouldShowFooter) {
              setShouldShowFooter(true);
            }
          }}
          className={`${styles.pageContainer} ${isMobile ? styles.app : ""}`}
        >
          <div className={styles.detailsContainer}>
            <div
              className={`${styles.imgContainer} ${
                product.imgUrls.length === 1 ? styles.single : ""
              } no-swiping`}
            >
              {product.imgUrls.map((src, idx) => (
                <div className={`${styles.mainImg} `}>
                  <Image
                    src={src}
                    fallback={
                      product.parsing_path ?? product.feature_parse_path
                    }
                    className={styles.img}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="floatController">
            {/* <ChatOnProductButton onChat={onQueryOnProduct} /> */}
            <div className={styles.contentContainer}>
              <ActionsOnProduct
                onAction={(...x) => actionResolver(...x)}
                selectedAction={selectedAction ?? undefined}
                actions={[
                  "gigi",
                  (product.prev_price || product.price) > 120
                    ? "dupe"
                    : "premium",
                ]}
              />
              <div className={styles.metaContent}>
                <div className="column gapMd">
                  <DesktopMedia>
                    <div className="flex spaceBetween alignCenter">
                      <div className={styles.brand}>
                        {camelCaseToCapitalized(product.brand)}
                      </div>
                    </div>
                  </DesktopMedia>

                  <div className="column gapLg">
                    <div className="flex">
                      <div className="column gapMd flex1">
                        <div className={styles.brand}>{product.brand}</div>

                        <div className={styles.title}>{product.title}</div>
                        <div className="alignCenter gapMd">
                          {(!product.prev_price ||
                            product.prev_price <= product.price) && (
                            <div className={styles.price}>
                              ${product.price.toFixed(2)}
                            </div>
                          )}

                          {!!product.prev_price &&
                            product.prev_price > product.price && (
                              <>
                                <div
                                  className={`${styles.price} ${styles.prev}`}
                                >
                                  ${product.prev_price.toFixed(2)}
                                </div>
                                <div
                                  className={`${styles.price} ${styles.current}`}
                                >
                                  ${product.price.toFixed(2)}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                      <LikeButton
                        onClick={() => onToggleLike(product._id!)}
                        isLiked={likedProducts.includes(product._id!)}
                      />
                    </div>

                    {!isEmpty(product.available_sizes) && (
                      <div className="column gapMd">
                        <span style={{ fontSize: "14px" }}>Size</span>
                        <div
                          style={{ overflow: "auto" }}
                          className="alignCenter gapMd"
                        >
                          {product.available_sizes?.map((s) => (
                            <Pill onClick={noop} size="sm">
                              <div className={styles.sizeBox}>
                                <span>{s}</span>
                              </div>
                            </Pill>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <DesktopMedia>
                  <div className="column gapLg">
                    <div className={styles.actionsBar}>
                      <Button type="secondary" onClick={noop}>
                        Add to session
                      </Button>
                    </div>
                  </div>
                </DesktopMedia>
                {product.vendors.length <= 3 && (
                  <div className="column gapMd">
                    <div className={`${styles.singleVendorOffer} no-swiping`}>
                      <Button
                        size="lg"
                        type="primary"
                        onClick={() =>
                          isMobile
                            ? onGoToVendor(product.vendors[0].url)
                            : window.open(product.vendors[0].url, "_blank")
                        }
                      >
                        Shop with {product.vendors[0].name}
                      </Button>
                    </div>
                  </div>
                )}

                {product.vendors.length > 3 && (
                  <div className={styles.vendors}>
                    {product.vendors.map((vendor) => (
                      <div className={styles.vendor}>
                        <div className="column gapMd">
                          <div className="alignCenter gapMd">
                            <div className={styles.vendorName}>
                              {vendor.name}
                            </div>
                          </div>
                          <SingleVendorDetails product={product} />
                        </div>
                        <div className={styles.vendorPrice}>
                          ${product.price}
                          <div className={styles.shop}>
                            <Button
                              type="primary"
                              onClick={() => {
                                const tab = window.open(vendor.url, "_blank");
                                tab?.blur();
                                window.focus();
                              }}
                            >
                              <div style={{ width: "70px" }}>Shop</div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {product.description && (
                  <InfoSection
                    title="Description"
                    content={product.description}
                  />
                )}

                {/* <div className={styles.boxMessage}>
                  <div className={styles.messageTitle}>
                    <Gigi width={32} height={32} />
                    Gigi is here to help...
                  </div>
                  <div className={styles.optionsWrap}>
                    <Pill isSecondary onClick={() => onQueryOnProduct()}>
                      <AiIcon width={16} height={16} fill="#000000" />
                      Chat with Gigi
                    </Pill>
                    {[
                      "Find dupes",
                      "Sustainable options",
                      "Change something",
                    ].map((opt) => (
                      <Pill onClick={noop}>{opt}</Pill>
                    ))}
                  </div>
                </div> */}
              </div>

              <div
                ref={scrollRef}
                style={{ paddingTop: "16px" }}
                className={`column gapLg ${styles.sidePadding}`}
              >
                {/* <div className={styles.similarTitle}>
                  <span>More to explore</span>
                </div> */}
                {/* <div className={styles.boxMessage}>
                  <div className={styles.messageTitle}>
                    <Gigi width={32} height={32} />
                    Gigi is here to help...
                  </div>
                  <div className={styles.optionsWrap}>
                    <Pill isSecondary onClick={() => onQueryOnProduct()}>
                      <AiIcon width={16} height={16} fill="#000000" />
                      Chat with Gigi
                    </Pill>
                    {[
                      "Find dupes",
                      "Sustainable options",
                      "Change something",
                    ].map((opt) => (
                      <Pill onClick={noop}>{opt}</Pill>
                    ))}
                  </div>
                </div> */}
                {!isFakeLoading && (
                  <SimilarProducts
                    onQueryOnProduct={onQueryOnProduct}
                    product={product}
                    filters={filters}
                    onChangeFilters={(f) => setFilters(f)}
                    actionProps={{
                      selectedAction: selectedAction ?? undefined,
                      onAction: actionResolver,
                      actions: [
                        (product.prev_price || product.price) > 120
                          ? "dupe"
                          : "premium",
                      ],
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <ProductActionsFooter onSave={noop} onShop={noop} onRefine={noop} />
          {/* {shouldShowFooter && <Footer />} */}
        </div>
      )}

      {/* {!isFakeLoading && (
        <ListSavingSelection
          isOpen={windowName === "save"}
          onClose={() => setWindow(null)}
          onSave={onSaveProductToList}
        />
      )} */}
    </>
  );
};

const SingleVendorDetails: React.FC<{ product: ProductDetails }> = ({
  product,
}) => {
  return (
    <div className={styles.singleVendorDetails}>
      <Rating
        size="small"
        name="half-rating-read"
        defaultValue={4.5}
        precision={0.5}
        readOnly
        sx={{ color: "#FFDC80" }}
      />
      <div className="flex alignCenter gapMd">
        <LocalShippingOutlinedIcon sx={{ color: "#737373" }} fontSize="small" />
        {/*   TODO:missing this in db  */}
        {/* {product.vendors[0].shipping.min}-{product.vendors[0].shipping.max} */}
        3 - 5 days | Free shipping
      </div>
    </div>
  );
};

export const SmallText: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <h6>{children}</h6>;
};

export const ChatOnProductButton: React.FC<{ onChat: () => void }> = ({
  onChat,
}) => {
  const [isShown, setIsTextShown] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTextShown(true);
  //     setTimeout(() => {
  //       setIsTextShown(false);
  //     }, 2500);
  //   }, 1000);
  // }, []);
  return (
    <div
      onClick={onChat}
      style={{
        position: "absolute",
        zIndex: 1000,
        top: "-55px",
        right: "20px",
      }}
    >
      <div
        id="ChatOnProductButton"
        style={{
          transition: ".3s",
          padding: "16px",
          display: "flex",
          fontSize: "18px",
          alignItems: "center",
          backgroundColor: "#ff2f40",
          color: "white",
          // justifyContent: "center",
          // gap: "8px",
          borderRadius: "50px",
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
        }}
      >
        <AutoAwesomeOutlinedIcon sx={{ color: "white" }} fontSize="inherit" />
        {
          <div
            style={{
              transition: ".8s",
              maxWidth: isShown ? "200px" : "0px",
              // width: isShown ? "unset" : "0px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "flex",
              fontWeight: 600,
              gap: "8px",
              fontSize: "14px",
            }}
          >
            <span />I like it but...
          </div>
        }
      </div>
    </div>
  );
};

export const MemoizedDetailsPage = React.memo(
  ProductDetailsPage,
  (prev, next) => isEqual(prev.product, next.product)
);

const LikeButton: React.FC<{ onClick: () => void; isLiked: boolean }> = ({
  onClick,
  isLiked,
}) => {
  return (
    <div onClick={onClick}>
      <div id="likeButton" className={styles.likeButtonStyle}>
        {isLiked ? (
          <Liked width={20} height={20} />
        ) : (
          <Like width={20} height={20} />
        )}
      </div>
    </div>
  );
};

export const InfoSection: React.FC<{ title: string; content: ReactNode }> = ({
  title,
  content,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        borderTop: "thin solid #00000010",
        borderBottom: "thin solid #00000010",
      }}
    >
      <AccordionItem
        onToggle={() => setIsOpen(!isOpen)}
        title={title}
        content={content}
        isOpen={isOpen}
      />
    </div>
  );
};
