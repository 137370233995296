import { MiniProduct } from "../../../../@types/entities/product.types";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";

import s from "./HomeView.module.scss";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";

export const RecentlyViewed: React.FC<{
  onClickProduct: (p: MiniProduct) => void;
  products: MiniProduct[];
}> = ({ products, onClickProduct }) => {
  return (
    <>
      <ViewPreviewTemplate
        title="Recently Viewed"
        cta="View All"
        onClickCta={() => {}}
      >
        <div className={s.feedContentScroller}>
          {!!products &&
            products
              .slice(0, 5)
              .map((p) => (
                <ProductCard product={p} onClick={onClickProduct} hideLike />
              ))}
        </div>
      </ViewPreviewTemplate>
      {/* <SlidingWindow
        isOpen={!!windowName}
        onClose={() => setWindow("")}
        title={"My Curated Feed"}
        direction="right"
      >
        <FeedV2 />
      </SlidingWindow> */}
    </>
  );
};
