import { Pill } from "../../../pill/Pill";
import { FilterValuesProps } from "../types";
import { Values } from "../values/Values";

export const LabelValues: React.FC<FilterValuesProps> = ({
  onChange,
  options,
  selectedOptions,
}) => {
  return (
    <Values>
      {options.map((option) => (
        <Pill
          size="sm"
          type="secondary"
          onClick={() => onChange(option)}
          isSelected={selectedOptions && selectedOptions.includes(option)}
        >
          {option}
        </Pill>
      ))}
    </Values>
  );
};
