import { ReactNode } from "react";
import { usePageStyles } from "../../hooks/ui/usePageStyles";
import s from "./ActionsFooter.module.scss";

export const ActionsFooter = ({ children }: { children: ReactNode }) => {
  const { paddingBottom } = usePageStyles();
  return (
    <div className={s.container} style={{ paddingBottom }}>
      <div className={s.footer}>{children}</div>
    </div>
  );
};
